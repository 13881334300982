.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  /* background-color: #f5f5f5; */
  /* padding: 10px; */
}

.wrapper-fullscreen {
  display: flex;
  /* height: 100vh; */
  width: 100vw;
  background-color: black;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* padding-right: 30px */
}

.main-container-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 75vw; */
  background-color: black;
  height: 100vh;
  width: 100vw;
  /* padding: 20px; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
}

.customer-info-swiper {
  width: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: justify;
  margin-bottom: 10px;
  margin-top: 10px;
  /* border: 1px solid orange */
}

.id {
  font-weight: bold;
  font-size: 1.2rem;
  color: #369;
}

.info {
  font-size: 1rem;
  color: #369;
}

.x-button-container {
  position: absolute;
  top: 8%;
  left: 1%;
  z-index: 1;
}

.list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 60vh;
}

.list-image {
  width: 110px;
  /* height: 100%; */
  object-fit: contain;
  border-radius: 5px;
  padding: 5px;
  border: 2px solid #fff;
  cursor: pointer;
}

.list-image-active {
  border: 2px solid #369;
}

/* PC */
@media screen and (min-width: 1200px) {
  .main-container-fullscreen {
    top: 7%;
  }

  .main-container {
    justify-content: flex-start;
  }
}

/* TABLET PORTRAIT */
@media screen and (max-width: 1024px) and (min-width: 600px) and (orientation: portrait) {
  .main-container-fullscreen {
    width: 100%;
    padding: 0;
    border-radius: 0;
  }

  .x-button-container {
    top: 1%;
  }
}

/* TABLET LANDSCAPE (WILL ALSO APPLY TO PHONE LANDSCAPE) */
@media screen and (max-width: 1180px) and (min-width: 600px) and (orientation: landscape) {
  .x-button-container {
    top: 2%;
  }
}

/* PHONE LANDSCAPE */
@media screen and (max-width: 900px) and (orientation: landscape) {
  .main-container {
    height: auto;
  }

  .main-container-fullscreen {
    height: 100vh;
  }

  .wrapper-fullscreen {
    height: auto;
  }

  .list-container {
    width: 50%;
  }

  .customer-info-swiper {
    width: 50%;
  }
}

/* PHONE PORTRAIT */
@media screen and (max-width: 600px) and (orientation: portrait) {
  .main-container-fullscreen {
    width: 100%;
    padding: 0;
    border-radius: 0;
  }

  .customer-info-swiper {
    width: 80%;
  }

  .list-container {
    width: 85%;
  }

  .list-image {
    width: 75px;
  }
}
