.submit {
  align-self: center;
  background-image: linear-gradient(90deg, #0cf, #0cf);
  border-radius: 5px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15);
  color: white;
  font-size: large;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 100px;
  padding: 20px;
  padding-left: 75px;
  padding-right: 75px;
  transition: 1s ease all;
}

.submit:hover {
  cursor: pointer;
  background-image: none;
  border-color: #0cf;
  border-width: 2px;
  color: #0cf;
}

.primary {
  align-self: center;
  background-image: linear-gradient(270deg, rgb(45, 183, 230), rgb(28, 122, 153), #369);
  border-radius: 5px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15);
  color: white;
  font-size: large;
  font-weight: bold;
  margin: 30px;
  padding: 15px;
  padding-left: 45px;
  padding-right: 45px;
  transition: 1s ease all;
}

.primary:hover {
  cursor: pointer;
}
