.form-section {
  align-items: flex-start;
  background-color: #EFEFEF;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 800px;
  transition: all 1s ease;
  width: 80vw;
}

.form-section-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.form-section-header > img {
  width: 30px;
}

.header-text {
  font-family: "GothamBold";
}

.rotate {
  transition: all 1s ease;
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.one_column {
  width: 100%;
}

.two_column {
  display: grid;
  grid-gap: 20px;
  width: 100%;
  grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
  
}
.three_column {
  display: grid;
  grid-gap: 20px;
  width: 100%;
  grid-template-columns: repeat( auto-fill, minmax(200px, 1fr) );
}

@media only screen and (max-width: 850px) {
  .form-section {
    width: 80vw;
  }

  .horizontal {
    display: grid;
    grid-template-columns: 1fr;
  }

  .image-grid {
    display: grid;
    grid-template-columns: 1fr;
  }
  
  .submit-button {
    width: 200px;
  }
}