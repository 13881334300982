.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.image-fullscreen {
  object-fit: cover;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 0;
}

/* Swiper Setup. (Image Carousel) */
.swiper-zoom-container {
  border-radius: 10px;
  overflow: hidden;
}

.swiper-container {
  /* display: inline; */
  width: 34vw;
  max-height: 70vw;
  border-radius: 11px;
  /* border: 2px solid lightgray; */
  overflow: hidden;
  z-index: 0;
  margin-bottom: 10px;
}

.swiper-container-fullscreen {
  display: flex;
  height: 100vh;
  border-radius: 11px;
  position: absolute;
  top: 0;
  /* left: 10%; */
  justify-content: center;
}

@media screen and (min-width: 1200px) {
  .swiper-container {
    width: 35vw;
  }
}

/* PHONE PORTRAIT */
@media screen and (max-width: 600px) and (orientation: portrait) {
  .swiper-container {
    width: 85vw;
  }
  .swiper-container-fullscreen {
    width: 100vw;
    border-radius: 0;
    align-items: center;
  }
  .image-fullscreen {
    width: 100%;
  }
}

/* TABLET PORTRAIT */
@media screen and (max-width: 1024px) and (min-width: 600px) and (orientation: portrait) {
  .swiper-container {
    width: 88vw;
  }

  .swiper-container-fullscreen {
    width: 100vw;
    border-radius: 0;
    align-items: center;
  }

  .image-fullscreen {
    width: 100vw;
  }
}

/* TABLET LANDSCAPE */
@media screen and (max-width: 1180px) and (min-width: 900px) and (orientation: landscape) {
  .swiper-container {
    width: 50vw;
  }

  .swiper-container-fullscreen {
    height: 100vh;
    width: 80vw;
    border-radius: 0;
    /* align-items: center; */
  }

  .image-fullscreen {
    height: 100%;
  }
}

/* PHONE LANDSCAPE */
@media screen and (max-width: 900px) and (orientation: landscape) {
  .swiper-container {
    width: 50vw;
  }

  .swiper-container-fullscreen {
    width: 50vw;
    border-radius: 0;
  }

  .image-fullscreen {
    height: 100vh;
    object-fit: contain;
  }
}

.swiper-button-prev,
.swiper-button-next {
  /* margin-top: -40px; */
  border-radius: 6px;
  padding: 20px;
  color: white !important;
  fill: white 0.5 !important;
  font-size: small;
}

.swiper-button-prev {
  margin-left: -15px;
  border: 0.5px solid gray;
  background-color: rgba(150, 150, 150, 0.6);
  top: 45% !important;
}
.swiper-button-next {
  margin-right: -15px;
  border: 0.5px solid gray;
  background-color: rgba(150, 150, 150, 0.6);
  top: 45% !important;
}

.swiper-pagination {
  padding-bottom: 3%;
}

:root {
  --swiper-theme-color: #fff !important;
  --swiper-navigation-size: 30px !important;
}
/* Swiper Setup. (Image Carousel) */
