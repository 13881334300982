.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input-wrapper > input,
.input-wrapper > textarea {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15);
  background-color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px;
  height: 40px;
  width: 90%;
  max-width: 400px;
}

.multi-input {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15);
  background-color: white;
  border: none;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  height: 40px;
}


input:focus { 
  outline-color: #0cf;
}

textarea:focus { 
  outline-color: #0cf;
}


.input-wrapper > label,
.file-input > label {
  font-weight: normal;
  font-family: "GothamMedium";
  font-size: medium;
  margin-bottom: 10px;
}