.dropdown-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dropdown-wrapper > select {
  background-color: white;
  border-radius: 5px;
  border-width: 0;
  padding: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15);
  height: 40px;
  width: 90%;
  max-width: 600px;
  /* min-width: 250px; */
}

select:focus {
  outline-color: #0cf;
}

.dropdown-label {
  font-family: "GothamMedium";
  font-size: medium;
  font-weight: normal;
  margin-bottom: 10px;
}