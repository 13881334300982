.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  /* padding: 10px; */
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #fff;
  color: inherit;
  border: 1px solid #ccc;
  cursor: pointer;
  text-transform: none;
}

.error {
  color: red;
}

.proposal {
  margin: 5px;
  width: 100%;
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
}

.proposal-info {
  color: #369;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customer-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
}

.proposals-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  /* background-color: #fff; */
}

.spinner {
  position: absolute;
  top: 40%;
  left: 49%;
}

h1 {
  font-size: 1.5em;
  margin: 0.67em 0;
  color: #369;
  padding-bottom: 20px;
  padding-top: 20px;
}
