.multiselect-area {
  align-items: flex-start;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
}

.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.checkbox-row {
  display: flex;
  align-items: center;
  margin: 5px;
  margin-right: 30px;
}

.shingle-layers-label {
  border-bottom: 1px solid white;
  margin-top: 25px;
}

.checkbox {
  box-shadow: none;
}

.multiselect-title {
  font-size: large;
}

.multi-label {
  font-size: medium;
  margin-left: 5px;
}