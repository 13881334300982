.service_auto {
  border: none;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 30px;
  min-width: 250px;
  height: 30px;
  padding: 10px;
  width: 100%;
}

#mts_equipmentreplacted {
  
}

label {
  font-weight: bold;
}
