.container {
    width: 80%;
    height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.header {
    /* background: #65c900; */
    color: #65c900;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
}

.header-text {
    color: #65c900;
    font-family: "Roboto",
        "Helvetica",
        "Arial",
        sans-serif;
    font-size: 55px;
    /* margin-right: 50px; */
}

.select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    width: 80%;
}

.MuiInputBase-root {
    width: 100%;
    font-size: 16px;
    color: gray;
}

.MuiTextField-root {
    width: 100%;
}

.info-text {
    font-size: 35px;
    font-weight: bold;
    color: #65c900;
    font-family: "Roboto",
            "Helvetica",
            "Arial",
            sans-serif;
    text-align: center;
    line-height: 1.5;
}